﻿import React, { Component } from 'react';

import Input from "../../../shared/components/Input";
import Checkbox from "../../../shared/components/Checkbox";
import TranslationService from "../../../shared/services/TranslationService";
import ValidationService from "../../../shared/services/ValidationService";

// Lodash functions
let forOwn = require('lodash/forOwn');
let isEmpty = require('lodash/isEmpty');
let extend = require('lodash/extend');

const _ = {
	forOwn: forOwn,
	isEmpty: isEmpty,
	extend: extend
};

class BarkerComponent extends Component {
	
	constructor() {
		super();
		this.state = {
			visible: false,
			plateNumber: {
				value: '',
				error: ''
			},
			ssnOrgNr: {
				value: '',
				error: ''
			},
			privateCompany: {
				value: false,
				error: ''
			},
			translations: []
		};

		this.postData = {
			registrationNumber: '',
			customerId: '',
			privateCompany: false,
			brand: 'TO'
		}

		this.submitFired = false;
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		setTimeout(() => {
			let newState = {};
			_.extend(newState, this.state);
			newState.visible = true;
			this.setState(newState);
		});
	}

	handlePlateNumberChange = e => {
		let newState = $.extend({}, this.state.plateNumber);
		newState.value = e.target.value.toLocaleUpperCase();
		this.postData.registrationNumber = e.target.value;
		this.setState({plateNumber: newState}, () => {
			this.handlePlateNumberValidation();
		});
	}

	handleSsnOrgNrChange = e => {
		let newState = $.extend({}, this.state.ssnOrgNr);
		newState.value = e.target.value;
		this.postData.customerId = e.target.value;
		this.setState({ssnOrgNr: newState}, () => {
			this.handleSsnOrgNrValidation();
		});
	}

	handlePrivateCompanyChange = e => {
		let newState = $.extend({}, this.state.privateCompany);
		newState.value = e.target.checked;
		this.postData.privateCompany = e.target.checked;
		this.setState({ privateCompany: newState });
	}

	// Handle validation
	handlePlateNumberValidation = () => {
		if (this.submitFired) {
			const rules = {
				plateNumber: 'required|plateNumber'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/startpage/barker/regnrerror'),
				plateNumber: TranslationService.getTranslation('/startpage/barker/regnrwrongformaterror')
			}

			let stateObject = ValidationService.validate(this.state.plateNumber, rules, customErrorMessages);
			this.setState({plateNumber: stateObject});
		}
	}

	handleSsnOrgNrValidation = () => {
		if (this.submitFired) {
			const rules = {
				ssnOrgNr: 'required|orgSsn'
			}

			const customErrorMessages = {
				required: TranslationService.getTranslation('/startpage/barker/pnrorgnrerror'),
				orgSsn: TranslationService.getTranslation('/startpage/barker/pnrorgnrerror')
			}

			let stateObject = ValidationService.validate(this.state.ssnOrgNr, rules, customErrorMessages);
			this.setState({ssnOrgNr: stateObject});
		}
	}

	handleSubmit = () => {
		this.submitFired = true;
		this.handlePlateNumberValidation();
		this.handleSsnOrgNrValidation();

		if (!this.hasErrorInForm()) {

			let linkerParam = '';
			if (window.ga) {
				let trackers = window.ga.getAll();

				if (trackers.length > 0) {
					linkerParam = trackers[0].get('linkerParam');
				}
			}

			let url = window.barkerAppObject.redirectUrl + '?' + linkerParam + '&affiliate=Toyota';
			if (window.barkerAppObject.redirectUrl.indexOf('#') > -1) {
				let urlStrings = window.barkerAppObject.redirectUrl.split('#');
				if (urlStrings.length > 1) {
					url = urlStrings[0];
					url += '?' + linkerParam + '&affiliate=Toyota' + '#';
					url += urlStrings[1];
				}
			}

			this.postData.redirectUrl = url;

			let postObject = {
				url: window.barkerAppObject.apiToyotaCookieUrl,
				form: this.postData
			}

			BM.forms.createAndPostForm(postObject);
		}
	}

	hasErrorInForm() {
		var result = false;
		_.forOwn(this.state, (stateObject) => {
			if (stateObject.error && !_.isEmpty(stateObject.error)) {
				result = true;
			}
		});

		return result;
	}

	getPulLink = () => {
		return window.barkerAppObject.pulLink;
	}

	render() {
		return (
			<div className={`row barker-wrapper` + (this.state.visible ? ' fadeIn' : ' fadeOut')}>
				<div className="col">
					<div className="barker-container">
						<div className="barker">
							<h2 className="mb-1">{TranslationService.getTranslation('/startpage/barker/heading')}</h2>
							<div className="row pb-1">
								<div className="col-12">
									<Input label={TranslationService.getTranslation('/startpage/barker/regnr')}
											id="plateNumber"
											formGroupClass={`mb-1`}
											value={this.state.plateNumber.value}
											error={this.state.plateNumber.error}
											plateInput={true}
											maxLength={6}
											placeholder={TranslationService.getTranslation('/placeholders/regnr')}
											onChange={this.handlePlateNumberChange}
											onValidate={this.handlePlateNumberValidation}
											onKeyPress={this.handleSubmit}/>
								</div>
								<div className="col-12">
									<Input label={TranslationService.getTranslation('/startpage/barker/pnrorgnr')}
									       id="plateNumber"
									       formGroupClass={`mb-1`}
									       value={this.state.ssnOrgNr.value}
									       error={this.state.ssnOrgNr.error}
									       placeholder={TranslationService.getTranslation('/placeholders/orgnr')}
									       onChange={this.handleSsnOrgNrChange}
									       onValidate={this.handleSsnOrgNrValidation}
									       onKeyPress={this.handleSubmit}/>
									<Checkbox id="privateCompany"
											  text={TranslationService.getTranslation('/startpage/barker/privatecompany')}
											  value={this.state.privateCompany.value}
											  error={this.state.privateCompany.error}
											  onChange={this.handlePrivateCompanyChange}
											  onKeyPress={this.handleSubmit}/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-start">
									<a href={this.getPulLink()}>{TranslationService.getTranslation('/startpage/barker/userinfo')}</a>
								</div>
								<div className="col-12 col-lg-6 mt-1 mt-lg-0 d-flex justify-content-center justify-content-lg-start">
									<button type="submit" 
											className="btn btn-primary"
											onClick={this.handleSubmit}>
										{TranslationService.getTranslation('/startpage/barker/button')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default BarkerComponent;