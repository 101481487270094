﻿import React from 'react';
import ReactDOM from 'react-dom';

import BarkerComponent from "./components/ToyotaBarkerComponent";

if (document.getElementById('barker-app')) {
	ReactDOM.render(
		<BarkerComponent/>,
		document.getElementById('barker-app')
	);
}